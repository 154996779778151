<template>
  <v-container>
    <v-toolbar
      dark
      dense
      color="purple"
    >
      <h1>Lista de prioridades o proyectos</h1>
    </v-toolbar>
    
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          flat
          class="ma-2"
        >
          Este sera tu espacio de proyectos y tareas, agrega cuantos proyectos quieras y organizalos acomodando de mayor a menor segun sea su relevancia.
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="12"
      >
        <v-card>
          <v-toolbar
            flat
            dense
            color="white"
          >
            <h3> Lista de prioridades  HACER DRAG AND DROP</h3>
            <v-spacer />

            <v-btn
              align="right"
              color="blue"
              dark
              small
              class="ma-2"
              @click="consultar"
            >
              Consultar
            </v-btn>


            <v-btn
              align="right"
              color="orange"
              dark
              small
              class="ma-2"
              :to="{ name: 'proyecto' }"
            >
              Nuevo
            </v-btn>
          </v-toolbar>

          <v-list
            class="pt-0"
            flat
          >
            <draggable
              v-model="proyectos"
            >
              <div
                v-for="proyecto in proyectos"
                :key="proyecto.id"
              >
                <v-list-item
                  :class="{ 'red lighten-5' : proyecto.done }"
                >
                  <template v-slot:default>
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="proyecto.done"
                        color="red"
                      />
                    </v-list-item-action>
            
                    <v-list-item-content>
                      <v-list-item-title>{{ proyecto.nomproy }}</v-list-item-title>
                      <v-list-item-subtitle>{{ proyecto.obs }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        icon
                        @click.stop="deleteProyecto(proyecto.id)"
                      >
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="black">
                          mdi-drag-horizontal-variant
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider />
              </div>
            </draggable>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
  },
  data() {
    return {
      proyectos: [],
      miscitas: []
    }
  },

  created(){
    this.consultar()
  },

  methods: {

    consultar(){
      // limpiar
      this.miscitas= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/proyectos.list" ).then(response=>{
        console.log("response proy", response)

        if (response.data.error===null){

          this.proyectos = response.data.result

          // this.mispro.forEach( element => {
          //   element.fechastart = element.fechastart.substr(0,10)
          //   element.hora = element.fechastart.substr(11,17)
          // })
          // console.log("citas", this.miscitas)
        }
      }).catch(error=>{
        console.log(error)
      })
    },
      
    ver(item){
      console.log("orden", item.idorden)
      this.verOrden(item.idordenes)
    },

    doneProyecto(id) {
      let proyecto = this.proyectos.filter(proyecto => proyecto.id === id)[0]
      proyecto.done = !proyecto.done
    },

    deleteProyecto(id){
      this.proyectos = this.proyectos.filter(proyecto => proyecto.id !== id)
    }
  }
}
</script>